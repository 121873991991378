<template>
  <div class="forcedDeliveryBox">
    <p class="title">强制发货</p>
    <el-upload
      class="upload-demo"
      :headers="headers"
      drag
      name="kit_file"
      :action="action"
      multiple
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">
        将文件拖到此处，或
        <em>点击上传</em>
      </div>
      <div class="el-upload__tip" slot="tip">
        只能上传jpg/png文件，且不超过500kb
      </div>
    </el-upload>
  </div>
</template>
  
  <script>
import apiDetail from '@/api/other.js'
import { Message } from 'element-ui'
import { getToken } from '@/utils/auth'
export default {
  data() {
    return {
      headers: '',
      action: `${process.env.VUE_APP_BASE_API}/api/import_kit/`,
    }
  },
  created() {
    this.headers = {
      Authorization: `${getToken()}`,
    }
  },
  methods: {},
}
</script>
  <style lang="scss" scoped>
.forcedDeliveryBox {
  margin: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
    color: #111111;
    margin-bottom: 20px;
  }
}
</style>